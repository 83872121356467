import * as React from "react"
import { Link } from "gatsby"

// styles
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <main className="container px-32 py-20 text-gray-300">
      <title>Not found</title>
      <h1 className="text-5xl font-bold mb-10">Page not found</h1>
      <p className="text-2xl font-semibold mt-10 mb-40">
        Sorry <span role="img" aria-label="Pensive emoji"> 😔 </span> we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
      </p>
      <Link className="text-4xl font-semibold text-grad" to="/">Go home</Link>
    </main>
  )
}

export default NotFoundPage
